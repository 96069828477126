<template lang="pug">
        div.col-lg-6.col-md-6.layout-spacing.mx-auto
            div.statbox.widget.box.box-shadow
                div.widget-header
                    div.row
                        div.col-xl-12.col-md-12.col-sm-12.col-12.text-center
                                h4 {{ $t('requestinventory.commplet')}}
                    vue-form(:state="formstate")
                        div.widget-content.widget-content-area
                            div.form-group.mb-4
                                label(for="sParentCenter") {{ $t('requestinventory.reqcenter')}}
                                select.form-control(id="sParentCenter" v-model="model.idRequestCenter")
                                    option(value="0" selected) {{ $t('headers.select')}}
                                    option(:value=" Center.idCenter" v-for="Center in CenterList") {{ Center.sCenterName }}

                            div.form-group.mb-4
                                label(for="iNumber") {{ $t('attendgroupInventory.nummanainv')}}
                                validate(tag="div")
                                    input.form-control(id="iNumber" required :min="1"  name="iNumber" type="number" placeholder="Numero de unidades de inventario" v-model="model.iRequestNumber")
                                    field-messages.text-danger(name="iNumber" )
                                        div(slot="required") {{ $t('attendgroupInventory.numcodreq')}}
                                        div(slot="min") {{ $t('attendgroupInventory.numcero')}}

                            
                            div.form-group.mb-4
                                label(for="sComments") {{ $t('attendgroupInventory.comments')}} 
                                validate(tag="div")
                                    input.form-control(type="text" id="sComments" required name="sComments" placeholder="Comentarios" v-model="model.sRequestComments")
                                    field-messages.text-danger(name="sComments" )
                                        div(slot="required") {{ $t('attendgroupInventory.commentsugg')}}                                                   

                            button.btn.btn-secondary(type="button" @click="Save") {{ $t('btns.btnsend')}}    
                                                                                 
</template>
<script>
/* eslint-disable */
export default {
    name: 'RequestInventory',
    data() {
        return {
            formstate: {},
            CenterList: [],
            model: {
                idRequestCenter: 0,
                iRequestNumber: 0,
                idPersonRequest: 0,
                sRequestComments: ''
            },
            
        }
    },
    methods: {
        async getCenterList() {
            await axios.get(apiURL + 'Center/getCenterListByAdmin?idPerson=' + localStorage.CertIdP, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                //Aqui falta quitar el centro administrador, el no puede solicitar inventario
                console.log(response.data.centerList)
                this.CenterList = response.data.centerList
            } )
            .catch( (error)  => {
                console.log("error")
            } )
        },

        async Save() {
            if(this.formstate.$invalid) {
                return;
            }
            
            this.openLoading(this.$t('msg.adminSwal1a'));
            this.model.idPersonRequest = localStorage.CertIdP

            await axios.post(apiURL + 'Center/requestInventory', this.model, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                Swal.close();
                if(response.data.bIsCorrect == false){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })
                    return
                }
                Swal.fire(this.$t('msg.adminSwal1'))
                router.push('/pages/home')
            } )
            .catch( (error) => {
                Swal.close();
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: error,
                    footer: ''
                })
            } )
        },
    },
    mounted() {
            
            //this.checkPermisions(this.$router.currentRoute.path)
            this.getCenterList()
        }    
        
}
</script>